/** @jsx jsx */
import { jsx, Container, Heading } from "theme-ui"
import { graphql } from "gatsby"
import { Layout, SEO, ContentfulRichText } from "../components"
import InvestorsForm from "../forms/investors"; 

const Investors = ({ data }) => {
  const {
    title,
    headingTitle,
    description,
    formTitle,
  } = data.contentfulInvestorsTemplate

  return (
    <Layout>
      <SEO title={title} />
      <Container sx={{ px: 3, py: [4, 5, 100], maxWidth: 832 }}>
        <div sx={{ mb: [4, 5, 100] }}>
          <Heading as="h1" sx={{ fontSize: 5, mb: 3 }}>
            {headingTitle}
          </Heading>
          <ContentfulRichText
            sx={{ "& a": { color: "primary" } }}
            content={description}
          />
        </div>
        <div>
          <Heading as="h2" mb={3}>
            {formTitle}
          </Heading>
          <InvestorsForm />
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulInvestorsTemplate(title: { eq: "Investors" }) {
      id
      title
      headingTitle
      description {
        raw
      }
      formTitle
    }
  }
`

export default Investors
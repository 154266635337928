/** @jsx jsx */
import {
  jsx,
  Box,
  Label,
  Input,
  Button,
  Flex,
  Select,
  Radio,
  Textarea,
} from "theme-ui"

export default ({ ...props }) => (
  <form
    sx={{ bg: "gray3", p: [3, 4] }}
    {...props}
    name="investors"
    method="POST"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    action="/thanks/"
  >
    <input type="hidden" name="form-name" value="investors" />
    <p css="display: none">
      <label>
        Don’t fill this out if you're human: <input name="bot-field" />
      </label>
    </p>
    <Flex sx={{ flexWrap: "wrap", justifyContent: "space-between", mb: 3 }}>
      <Box sx={{ width: ["100%", "48%"], my: [3, 0] }}>
        <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="firstName">
          First Name *
        </Label>
        <Input
          sx={{
            borderColor: "gray5",
            bg: "background",
            px: 3,
            py: 2,
          }}
          placeholder="First Name"
          name="firstName"
          type="text"
          id="firstName"
          required
        />
      </Box>
      <Box sx={{ width: ["100%", "48%"] }}>
        <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="lastName">
          Last Name *
        </Label>
        <Input
          sx={{
            borderColor: "gray5",
            bg: "background",
            px: 3,
            py: 2,
          }}
          placeholder="Last Name"
          name="lastName"
          type="text"
          id="lastName"
          required
        />
      </Box>
    </Flex>
    <Flex sx={{ flexWrap: "wrap", justifyContent: "space-between", mb: 3 }}>
      <Box sx={{ width: ["100%", "48%"], my: [3, 0] }}>
        <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="companyName">
          Company Name
        </Label>
        <Input
          sx={{
            borderColor: "gray5",
            bg: "background",
            px: 3,
            py: 2,
          }}
          placeholder="Company Name"
          name="companyName"
          type="text"
          id="companyName"
        />
      </Box>
      <Box sx={{ width: ["100%", "48%"] }}>
        <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="privateLender">
          Private Lender?
        </Label>
        <Select
          name="privateLender"
          id="privateLender"
          sx={{
            borderColor: "gray5",
            bg: "background",
            px: 3,
            py: 2,
          }}
        >
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Select>
      </Box>
    </Flex>
    <Flex sx={{ flexWrap: "wrap", justifyContent: "space-between", mb: 3 }}>
      <Box sx={{ width: ["100%", "48%"], my: [3, 0] }}>
        <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="yourCity">
          Your City *
        </Label>
        <Input
          sx={{
            borderColor: "gray5",
            bg: "background",
            px: 3,
            py: 2,
          }}
          placeholder="Your City"
          name="yourCity"
          type="text"
          id="yourCity"
          required
        />
      </Box>
      <Box sx={{ width: ["100%", "48%"] }}>
        <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="yourState">
          Your State
        </Label>
        <Input
          sx={{
            borderColor: "gray5",
            bg: "background",
            px: 3,
            py: 2,
          }}
          placeholder="Your State"
          name="yourState"
          type="text"
          id="yourState"
        />
      </Box>
    </Flex>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="email">
        Your Best Email *
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="Your Best Email"
        name="email"
        type="text"
        id="email"
        required
      />
    </Box>
    <Flex sx={{ flexWrap: "wrap", justifyContent: "space-between", mb: 3 }}>
      <Box sx={{ width: ["100%", "48%"], my: [3, 0] }}>
        <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="cellPhone">
          Cell Phone *
        </Label>
        <Input
          sx={{
            borderColor: "gray5",
            bg: "background",
            px: 3,
            py: 2,
          }}
          placeholder="Cell Phone"
          name="cellPhone"
          type="text"
          id="cellPhone"
          required
        />
      </Box>
      <Box sx={{ width: ["100%", "48%"] }}>
        <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="otherPhone">
          Other Phone
        </Label>
        <Input
          sx={{
            borderColor: "gray5",
            bg: "background",
            px: 3,
            py: 2,
          }}
          placeholder="Other Phone"
          name="otherPhone"
          type="text"
          id="otherPhone"
        />
      </Box>
    </Flex>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="website">
        Website
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="Website"
        name="website"
        type="text"
        id="website"
      />
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="propertyType">
        What types of properties are you looking for?
      </Label>
      <Select
        name="propertyType"
        id="propertyType"
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
      >
        <option value="Single Family Homes">Single Family Homes</option>
        <option value="Multi Family Homes">Multi Family Homes</option>
        <option value="Mobile Homes">Mobile Homes</option>
        <option value="Condos">Condos</option>
        <option value="Vacant Lots">Vacant Lots</option>
        <option value="Commercial Improved">Commercial Improved</option>
        <option value="Commercial Unimproved">Commercial Unimproved</option>
      </Select>
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="budget">
        What is your max budget?
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="What is your max budget?"
        name="budget"
        type="text"
        id="budget"
      />
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="purchaseDeals">
        How will you be funding your purchase of these deals?
      </Label>
      <Select
        name="purchaseDeals"
        id="purchaseDeals"
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
      >
        <option value="Unanswered">Unanswered</option>
        <option value="Cash">Cash</option>
        <option value="Hard money loans">Hard money loans</option>
        <option value="Private money loans">Private money loans</option>
        <option value="IRA">IRA</option>
        <option value="Conventional loans">Conventional loans</option>
        <option value="Other">Other</option>
      </Select>
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }}>
        Do you already have financing/funding in place?
      </Label>
      <Label>
        <Radio
          name="financingFundingPlace"
          value="true"
          defaultChecked={true}
        />
        No
      </Label>
      <Label>
        <Radio name="financingFundingPlace" value="false" />
        Yes
      </Label>
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="findDealFastClose">
        If we find you a deal, how fast can you close?
      </Label>
      <Select
        name="findDealFastClose"
        id="findDealFastClose"
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
      >
        <option value="Unanswered">Unanswered</option>
        <option value="In no hurry">In no hurry</option>
        <option value="Within a month">Within a month</option>
        <option value="Within a couple weeks">Within a couple weeks</option>
        <option value="Within a week">Within a week</option>
        <option value="In a couple days">In a couple days</option>
        <option value="As soon as title search completed!">
          As soon as title search completed!
        </option>
      </Select>
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="desireProfit">
        What is your desired profit for a flip?
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="What is your desired profit for a flip?"
        name="desireProfit"
        type="text"
        id="desireProfit"
      />
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="rentalCriteria">
        What is your rental criteria?
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="What is your rental criteria?"
        name="rentalCriteria"
        type="text"
        id="rentalCriteria"
      />
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="levelRepairsComfortable">
        What level of repairs are you comfortable with?
      </Label>
      <Select
        name="levelRepairsComfortable"
        id="levelRepairsComfortable"
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
      >
        <option value="Unanswered">Unanswered</option>
        <option value="Cosmetics Paint and Carpet">
          Cosmetics (paint and carpet)
        </option>
        <option value="Full remodels">Full remodels</option>
        <option value="Remodels with foundation problems">
          Remodels w/foundation problems
        </option>
        <option value="Fire damaged properties">Fire-damaged properties</option>
        <option value="I will take on anything">I'll take on anything</option>
      </Select>
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="hearAboutUs">
        How did you hear about us?
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="How did you hear about us?"
        name="hearAboutUs"
        type="text"
        id="hearAboutUs"
      />
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="anythingWeShouldKnow">
        Anything else we should know?
      </Label>
      <Textarea
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
          resize: "none",
        }}
        rows={8}
        placeholder="Anything else we should know?"
        name="anythingWeShouldKnow"
        id="anythingWeShouldKnow"
      />
    </Box>
    <div sx={{ textAlign: "right" }}>
      <Button
        sx={{
          px: 3,
          py: 2,
          bg: "secondary",
          border: theme => `1px solid ${theme.colors.secondary}`,
          fontWeight: "heading",
          cursor: "pointer",
        }}
        type="submit"
      >
        Send Me Deals
      </Button>
    </div>
  </form>
)
